<script setup lang="ts">
const { $directus, $readItem } = useNuxtApp()
const userData = useCookie<any>('userData')

const { data: navigation } = await useAsyncData(
  'mainNavigation',
  () => {
    return $directus.request(
      $readItem('navigation', 'main', {
        fields: [
          {
            items: [
              'id',
              'has_children',
              'title',
              'icon',
              'label',
              'type',
              'url',
              {
                page: ['permalink', 'title'],
                children: [
                  'id',
                  'title',
                  'has_children',
                  'icon',
                  'label',
                  'type',
                  'url',
                  {
                    page: ['permalink', 'title'],
                  },
                ],
              },
            ],
          },
        ],
      }),
    )
  },
  {
    transform: data => data,
  },
)

const indexItem = computed(() => {
  if (navigation.value && userData.value)
    navigation.value.items = navigation.value.items.filter(n => n.title !== 'お問い合わせ')

  return Math.ceil(navigation.value ? Math.ceil(navigation.value.items.length / 2) : 0)
})

if (!navigation.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}
</script>

<template>
  <VCol
    cols="12"
    md="2"
    sm="3"
  >
    <div
      v-for="(item, index) in navigation.items"
      :key="item.id"
    >
      <NuxtLink
        v-if="index < indexItem"
        :to="getNavItemUrl(item)"
        class="d-flex align-center"
      >
        <span
          class="ma-2"
          :class="$vuetify.theme.current.dark ? 'text-body-2' : 'text-white-variant'"
        >
          {{ item.title }}
        </span>
      </NuxtLink>
    </div>
  </VCol>

  <VCol
    cols="12"
    md="2"
    sm="3"
  >
    <div
      v-for="(item, index) in navigation.items"
      :key="item.id"
    >
      <NuxtLink
        v-if="index >= indexItem"
        :to="getNavItemUrl(item)"
        class="d-flex align-center"
      >
        <span
          class="ma-2"
          :class="$vuetify.theme.current.dark ? 'text-body-1' : 'text-white-variant'"
        >
          {{ item.title }}
        </span>
      </NuxtLink>
    </div>
  </VCol>
</template>
