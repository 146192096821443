<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router'

const { $directus, $readItem } = useNuxtApp()

const { data: navigation } = await useAsyncData('footerNav', () => {
  return $directus.request(
    $readItem('navigation', 'footer', {
      fields: [
        {
          items: [
            'id',
            'title',
            'icon',
            'label',
            'type',
            'url',
            'has_children',
            {
              page: ['permalink', 'title'],
              children: [
                'id',
                'title',
                'icon',
                'label',
                'type',
                'url',
                {
                  page: ['permalink', 'title'],
                },
              ],
            },
          ],
        },
      ],
    }),
  )
})
</script>

<template>
  <div
    v-if="navigation?.items"
    class="d-md-flex justify-space-between flex-wrap gap-x-4 gap-y-4 align-center"
  >
    <div
      v-for="item in navigation.items"
      :key="item.id"
    >
      <NuxtLink
        :to="getNavItemUrl(item) as RouteLocationRaw"
        class="text-body-2 text-white"
      >
        {{ item.title }}
      </NuxtLink>
    </div>
  </div>
</template>
