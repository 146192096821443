<script setup lang="ts">
import AuthProvider from '@/views/pages/authentication/AuthProvider.vue'
import footerDarkBg from '@images/front-pages/backgrounds/footer-bg-dark.png'
import footerLightBg from '@images/front-pages/backgrounds/footer-bg-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import logo from '@images/logo-text-footer.svg?raw'
import terms from '@/assets/pdf/場所とるPR利用規約.pdf'

const userData = useCookie<any>('userData')
const footerBg = useGenerateImageVariant(footerLightBg, footerDarkBg)
</script>

<template>
  <div class="footer">
    <div
      class="footer-top pt-15"
      :style="{ 'background-image': `url(${footerBg})` }"
    >
      <VContainer>
        <VRow>
          <!-- 👉 Footer  -->
          <VCol
            cols="12"
            md="6"
          >
            <div
              class="mb-4"
              :class="$vuetify.display.smAndDown ? 'w-100' : 'w-75'"
            >
              <NuxtLink :to="{ name: 'index' }">
                <div class="d-flex align-center gap-x-3 mb-4">
                  <VNodeRenderer :nodes="h('div', { innerHTML: logo })" />
                </div>
              </NuxtLink>
              <h4
                class="mb-2"
                :class="$vuetify.theme.current.dark ? 'text-body-1' : 'text-white-variant'"
              >
                電話でのお問い合わせはこちら
              </h4>

              <div class="d-flex gap-x-3 align-center">
                <div>
                  <VIcon
                    icon="tabler-phone-call"
                    size="24"
                  />
                </div>

                <div>
                  0120-840-109
                </div>
              </div>
              <div
                class="mb-2"
                :class="$vuetify.theme.current.dark ? 'text-body-1' : 'text-white-variant'"
              >
                受付時間：平日9：00 〜 18：00（窓口：株式会社アイフィールド）
              </div>
              <h4 :class="$vuetify.theme.current.dark ? 'text-body-1' : 'text-white-variant'">
                お気軽にお問い合わせください
              </h4>

              <NuxtLink v-if="!userData" :to="{ name: 'contact' }">
                <VBtn>
                  <VIcon
                    start
                    icon="tabler-mail"
                  />
                  お問い合わせフォーム
                </VBtn>
              </NuxtLink>
            </div>
          </VCol>
          <FooterCenter />
          <VCol
            v-if="!userData"
            cols="12"
            md="2"
            sm="6"
          >
            <div class="d-flex gap-x-4 align-center">
              <NuxtLink :to="{ name: 'member' }">
                <VBtn
                  variant="outlined"
                  color="primary"
                  rounded="pill"
                >
                  <span class="text-white ms-2 ">無料会員登録</span>
                </VBtn>
              </NuxtLink>

              <NuxtLink :to="{ name: 'login' }">
                <VBtn
                  variant="elevated"
                  rounded="pill"
                  color="primary"
                >
                  <span class="text-white ms-2">ログイン</span>
                </VBtn>
              </NuxtLink>
            </div>
          </VCol>
        </VRow>
      </VContainer>
    </div>

    <!-- 👉 Footer Line -->
    <div class="text-white-variant footer-line w-100">
      <VContainer>
        <div class="d-md-flex justify-space-between flex-wrap gap-y-4 align-center">
          <span class="text-wrap me-4">
            &copy;

            {{ new Date().getFullYear() }}

            <a
              href="https://i-fieldnet.com/"
              target="_blank"
              rel="noopener noreferrer"
              class="font-weight-bold ms-1 text-white"
            >I Field Co.Ltd </a>
          </span>

          <div class="d-md-flex justify-space-between flex-wrap gap-x-4 gap-y-4 align-center">
            <FooterBotton />
          </div>

          <div class="d-flex gap-x-6 align-center">
            <AuthProvider />
            <a
              :href="terms"
              rel="noopener noreferrer"
              class="font-weight-bold ms-1 text-white"
              target="_blank"
            >利用規約</a>
          </div>
        </div>
      </VContainer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-top {
  border-radius: 60px 60px 0 0;
  background-size: cover;
  color: #fff;
}

.footer-line {
  background: #171925;
}
</style>

<style lang="scss">
.subscribe-form {
  .v-label {
    color: rgba(211, 212, 220, 50%) !important;
  }

  .v-field {
    border-radius: 6px 0 0 6px!important;

    input.v-field__input::placeholder {
      color: rgba(231, 227, 252, 50%) !important;
    }
  }

}

.footer {
  border-radius: 50%;

  @media (min-width: 600px) and (max-width: 960px) {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}
</style>
